<template>
  <div class="w-per100 bg_343434 white_color ft16 px360">
    <div class="py60 row j_sb">
      <div>
        <div class="mb40">
          <img :src="footerinfo?.platform_logo_bottom" alt="" class="logow" />
        </div>
        <div class="ft14 white_color">客服咨询电话：</div>
        <div class="ft14 white_color mb20">{{ footerinfo?.platform_time }}</div>
        <div class="white_color ft28 bolder">{{ footerinfo?.platform_phone }}</div>
      </div>
      <div>
        <div class="ft18 white_color bolder mb36 pointer" @click="goWeb(1)" :class="hoverIndex1 == 100 ? 'c_D7000F' : ''"
          @mouseover="hoverIndex1 = 100" @mouseout="hoverIndex1 = -1">平台规则</div>
        <div class="ft14 c_AAAAAA">
          <div class="mb10 pointer" v-for="(item, index) in footerinfo?.platform_rule" :key="index"
            :class="index == hoverIndex1 ? 'c_D7000F' : ''" @mouseover="hoverIndex1 = index" @mouseout="hoverIndex1 = -1"
            @click="goWebDetails(1,item.id)">{{ item.title }}</div>
        </div>
      </div>
      <div>
        <div class="ft18 white_color bolder mb36 pointer" @click="goWeb(2)" :class="hoverIndex2 == 300 ? 'c_D7000F' : ''"
          @mouseover="hoverIndex2 = 300" @mouseout="hoverIndex2 = -1">买家指南</div>
        <div class="ft14 c_AAAAAA">
          <div class="mb10 pointer" @click="goRoute(1)" :class="hoverIndex2 == 100 ? 'c_D7000F' : ''"
            @mouseover="hoverIndex2 = 100" @mouseout="hoverIndex2 = -1">下订单</div>
          <div class="mb10 pointer" v-for="(item, index) in footerinfo?.buy_help" :key="index"
            :class="index == hoverIndex2 ? 'c_D7000F' : ''" @mouseover="hoverIndex2 = index" @mouseout="hoverIndex2 = -1"
            @click="goWebDetails(2,item.id)">{{ item.title }}</div>
          <div class="mb10 pointer" @click="goRoute(2)" :class="hoverIndex2 == 200 ? 'c_D7000F' : ''"
            @mouseover="hoverIndex2 = 200" @mouseout="hoverIndex2 = -1">确认收货</div>
        </div>
      </div>
      <div>
        <div class="ft18 white_color bolder mb36 pointer" @click="goWeb(3)" :class="hoverIndex4 == 100 ? 'c_D7000F' : ''"
          @mouseover="hoverIndex4 = 100" @mouseout="hoverIndex4 = -1">卖家指南</div>
        <div class="ft14 c_AAAAAA">
          <div class="mb10 pointer" v-for="(item, index) in footerinfo?.sell_help" :key="index" @click="goWebDetails(3,item.id)"
            :class="index == hoverIndex4 ? 'c_D7000F' : ''" @mouseover="hoverIndex4 = index" @mouseout="hoverIndex4 = -1">
            {{ item.title }}</div>
        </div>
      </div>
      <div>
        <div class="ft18 white_color bolder mb36 pointer" @click="goWeb(4)" :class="hoverIndex3 == 100 ? 'c_D7000F' : ''"
          @mouseover="hoverIndex3 = 100" @mouseout="hoverIndex3 = -1">帮助中心</div>
        <div class="ft14 c_AAAAAA">
          <div class="mb10 pointer" v-for="(item, index) in footerinfo?.help_center" :key="index" @click="goWebDetails(4,item.id)"
            :class="index == hoverIndex3 ? 'c_D7000F' : ''" @mouseover="hoverIndex3 = index" @mouseout="hoverIndex3 = -1">
            {{ item.title }}</div>
        </div>
      </div>
      <div>
        <div class="ft18 white_color bolder mb36">关注我们</div>
        <div class="">
          <img :src="footerinfo?.platform_ewm" alt="" class="wh96" />
        </div>
      </div>
    </div>
    <div class="py30 bdt_FFFFFF ft14 info_color text_center">
      <span class="pointer" @click="goHtml()">红源文化传播（深圳）有限公司 © {{ footerinfo?.platform_banquan }} 版权所有 ICP备案：{{ footerinfo?.platform_icp }}</span> 
    </div>
  </div>
</template>
<script>
import { webMsg } from "@/api/app";
export default {
  data() {
    return {
      footerinfo: "",
      hoverIndex1: -1,
      hoverIndex2: -1,
      hoverIndex3: -1,
      hoverIndex4: -1,
    };
  },
  props: {
    current: Number,
  },
  mounted() {
    this.fetchIndexBanner();
  },
  methods: {
    goHtml() {
      window.open(this.footerinfo.platform_icp_url, '_blank')
    },
    goRoute(val) { //路由跳转
      if (val == 1) {
        this.$router.push({
          path: '/shoppingMall',
          query: {
            id: val
          }
        })
      }
      if (val == 2) {
        this.$router.push({
          path: '/UserOrders',
          query: {
            id: val
          }
        })
      }
    },
    goWebDetails(ty,val) { //去详情页
      this.$router.push({
        path: '/webDetails',
        query: {
          value:ty,
          id: val
        }
      })
    },
    goWeb(val) {
      this.$router.push({
        path: '/webIndex',
        query: {
          value: val
        }
      })
    },
    //获取底部信息
    async fetchIndexBanner() {
      let { data } = await webMsg({
        type: 4 //底部信息type
      });
      console.log(data)
      this.footerinfo = data.data;  //底部信息
    },
  },
};
</script>
<style lang="scss" scoped>
.logow {
  width: 12.8125rem;
  height: 3.1875rem;
}
</style>
