<template>
  <div>
    <div class="w-per100 box-border fixed box-border left0 top0 zIndex100 primary_color ft20" style="height: 14rem;">
      <div class="h40px w-per100 px360 ft12 input_color row j_sb a_center bg_F7F6F9">
        <div class="row a_center">
          <div class="mr10 pointer" @click="goindex">
            <img src="../assets/img/home.png" alt="" class="wh12" />
          </div>
          <div class="mr20">您好，欢迎来到红源文化传播(深圳)有限公司！！</div>
          <div class="c_D7000F pointer" v-if="isLogin" @click="goUser">{{ username }}</div>
          <div class="c_D7000F pointer" style="margin-left: 1rem;" @click="outLogin" v-if="isLogin">退出</div>
          <div class="c_D7000F pointer" @click="userLogin" v-else>登录/注册</div>

        </div>
        <div>
          <span v-if="isLogin" class="pointer" @click="goShopT">我的购物车&emsp;|&emsp;</span>
          <span class="pointer" @click="goUser">加盟商中心</span>&emsp;|&emsp;<span class="pointer"
            @click="gohoutai">酒庄中心</span>&emsp;|&emsp;服务热线：{{ footerinfo?.platform_phone
            }}
        </div>
      </div>
      <div class="bg_w px360 w-per100 bdb_D7000F column j_sb" style="height: 11.5rem;">
        <div class="row a_center j_sb py20">
          <div @click="goindex">
            <img :src="footerinfo?.platform_logo" alt="" class="logo pointer" />
          </div>
          <div class="w550">
            <el-input v-model="inputShop" placeholder="请输入商品名称">
              <div slot="append" class="search pointer" @click="findShop">
                <i class="el-icon-search"></i> 搜索
              </div>
            </el-input>
          </div>
        </div>
        <div class="row a_center cascader">
          <div class="mr120">
            <!-- , checkStrictly:'true' -->
            <el-cascader v-model="value" :options="options" :props="props"  @change="handleChange" @active-item-change="handleItemChange"
              @expand-change="handeleExChange" placeholder="全部酒品分类"></el-cascader>
          </div>
          <div>
            <ul class="row a_center" style="list-style:none;float: left;;">
              <li class="pointer mr120 ft16 " :class="current == index ? 'c_D7000F' : 'primary_color'"
                v-for="(item, index) in navList" :key="index" @click="handleShow(index)"
                style="display:inline;flex-shrink: 0;">
                <span>{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="480px" custom-class="br4" center>
      <div style="min-height: 480px" class="login">
        <div class="row a_center j_center mb40 ft18 info_color">
          <div class="px20 pointer" :class="type == 1 ? 'typeact' : ''" @click="changeNav(1)">短信登录</div>
          <div class="px20 bdl_E4E4E9 pointer" :class="type == 2 ? 'typeact' : ''" @click="changeNav(2)">
            注册账号
          </div>
        </div>
        <div class="mb30">
          <el-input placeholder="请输入手机号" v-model="input1">
            <template slot="prefix"><img src="../assets/img/shoujihao.png" alt="" class="wh25" /></template>
          </el-input>
        </div>
        <div class="mb30">
          <el-input placeholder="请填写验证码" v-model="usercode">
            <template slot="prefix"><img src="../assets/img/yanzhengma.png" alt="" class="wh25" /></template>
            <template slot="suffix">
              <span class="ft14 c_D7000F pointer" v-if="!isWait" @click="fetchCode">获取验证码</span>
              <el-statistic :value="deadline" time-indices format="重新发送(ss秒)"
                :value-style="{ fontSize: '14px', color: '#D7000F' }" @finish="handleFinish" v-else>
              </el-statistic>
            </template>
          </el-input>
        </div>
        <div class="row a_center mb40" v-if="type == 2">
          <div :class="agreeShow ? 'seleiconact' : 'seleicon'" class="mr10" @click="changeAgree">
            <i class="el-icon-check" v-if="agreeShow"></i>
          </div>
          <div class="ft14 info_color">我已阅读并同意<span class="c_D7000F pointer" @click="goPage()">《用户注册协议》</span></div>
        </div>
        <div class="row a_center mb40" v-if="type == 1">
          <div :class="agreeLogin == 1 ? 'seleiconact' : 'seleicon'" class="mr10" @click="changeAgreeLogin">
            <i class="el-icon-check" v-if="agreeLogin == 1"></i>
          </div>
          <div class="ft14 info_color">登录永久有效</div>
        </div>
        <div class="sbmitbtn" @click="userSubmit" v-if="type == 1">立即登录</div>
        <div class="sbmitbtn" @click="userregSubmit" v-if="type == 2">立即注册</div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="msgdialogVisible" width="40rem" custom-class="br4" center title="用户协议">
      <div class=" ft18 row j_center" style="min-height: 5rem;max-height: 40rem;overflow-y: auto; padding: 0 15px;">
       <div v-html="content"></div>
      </div>
      <div class="row j_se" style="padding: 15px 0;">
        <!-- <el-button style="width: 100px;background-color: #DDDDDD ;color: #666666 ;"
                    @click="dialogVisible = false">取消</el-button> -->
        <el-button type="danger" style="width: 100px;background-color: #E1251B;"
          @click="msgdialogVisible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { getToken } from "@/utils/auth";
import { getCaptcha, login, register, userinfo, category, webMsg, producerCategory, jzCategory } from "@/api/app"
import { setToken, getToken, clearerToken } from "@/utils/auth"

export default {
  data() {
    return {
      agreeLogin: 0,
      agreeShow: false,
      isWait: false, //等待验证码
      deadline: 0,
      type: 1,
      dialogVisible: false, //登录注册弹窗状态
      input: "",
      input1: "", //用户手机号
      usercode: "",//验证码
      input2: "",//会员码
      username: "",//登录注册成功后显示
      // loginType:"",//token是否存在 登录状态
      footerinfo: "",//logo和客服
      inputShop: '',//搜索商品
      navList: [
        {
          name: "首页",
        },
        {
          name: "商城",
        },
        {
          name: "新闻中心",
        },
      ],
      value: [],
      options: [],
      Categoryarr: [],//酒庄
      Categoryarr2: [],//商品
      logo: "",
      isLogin: false,
      props: {
        value: 'idx', // 指定选项的值属性
        label: 'name', // 指定选项的标签属性
        children: 'children', // 指定选项的子选项属性
        checkStrictly: false, // 禁止只通过父节点值来选中子节点
        emitPath: false,
      },
      msgdialogVisible: false,
      content:''
    };
  },
  props: {
    current: Number,
    keyword: String,
  },
  mounted() {
    // console.log(this.isLogin)
    this.isLogin = false
    this.fetchIndexBanner();
    // this.getproducerCategory()
    this.arr()
    if (getToken()) {
      // console.log('111111111111111111111111111111111')
      this.isLogin = true
      this.keepUserInfo()
    }
    else {
      this.isLogin = false
    }
  },
  methods: {
    // //退出登录
    outLogin() {
      console.log('111')
      clearerToken()
      this.isLogin = false
      this.$router.push({
        path: '/'
      })
    },
    //保持登录状态
    async keepUserInfo() {
      let { data } = await userinfo()
      window.localStorage.setItem("user_type", data?.data?.info?.user_type)
      this.username = data?.data?.info?.username
    },
    //切换登录注册
    changeNav(val) { //登录
      if (this.type == val) {
        return
      }
      if (val == 1) {
        this.type = 1
      }
      if (val == 2) {
        this.type = 2
      }
      this.input1 = ''
      this.usercode = ''
      this.input2 = ''
      this.agreeShow = false
    },
    //登录
    async userSubmit() {
      // if (this.agreeShow == false) {
      //   this.$message.error('请同意用户登录协议');
      //   return
      // }
      let { data } = await login({
        mobile: this.input1,
        captcha: this.usercode,
        keep: this.agreeLogin
      })
      console.log(data)
      if (data.code == 1) {
        this.$message.success(data.msg);
        setToken(data.data.user_info.token)
        window.localStorage.setItem('user_type',data.data.user_info.user_type)
        this.username = data.data.user_info.username
        this.isLogin = true
        this.dialogVisible = false
        this.$router.go(0)
      }
      else {
        this.$message.error(data.msg);
      }
    },
    //注册
    async userregSubmit() {
      if (this.agreeShow == false) {
        this.$message.error('请同意用户注册协议');
        return
      }
      let { data } = await register({
        mobile: this.input1,
        captcha: this.usercode,
      })
      console.log(data)
      if (data.code == 1) {
        this.$message.success(data.msg);
        setToken(data.data.user_info.token)
        window.localStorage.setItem('user_type',data.data.user_info.user_type)
        this.username = data.data.user_info.username
        this.isLogin = true
        this.dialogVisible = false
        this.$router.go(0)
      }
      else {
        this.$message.error(data.msg);
      }
    },
    //是否同意注册协议
    changeAgree() {
      if (this.agreeShow == false) {
        this.agreeShow = true
        return
      }
      this.agreeShow = false
    },
    //是否永久登录
    changeAgreeLogin() {
      if (this.agreeLogin == 0) {
        this.agreeLogin = 1
        return
      }
      this.agreeLogin = 0
    },
    //登录注册点击事件
    userLogin() {
      this.dialogVisible = true
    },
    // 获取验证码
    async fetchCode() {
      // 注册
      if (!this.input1) {
        this.$message.error("请输入手机号");
        return;
      }
      if (!/^[1][3-9]\d{9}$/.test(this.input1)) {
        this.$message.error("手机号格式错误");
        return;
      }
      if (this.agreeShow == false && this.type == 2) {
        this.$message.error('请同意用户注册协议');
        return
      }
      let { data } = await getCaptcha({
        mobile: this.input1
      });
      if (data.code == 1) {
        console.log(data);
        this.isWait = true;
        this.deadline = Date.now() + 1000 * 60;
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    // 可发送
    handleFinish() {
      this.isWait = false;
    },
    //商品分类三级联动 .then里面的值无法拿出 对象必须以label和value为键值
    async arr() {
      let { data } = await producerCategory() //这里拿到第一个数组
		if( data.code==1 ){
      data?.data?.forEach(item => { //遍历第一个数组以拿取值和请求下一个数组
        item.idx = item.id+'x'
        item.children = []
    //   let obj1 = {} //第一数组的项
    //   let obj2 = {} //第二数组的项
    //   let obj3 = {} //第三数组的项
    //   jzCategory({ producer_id: item.id }).then(resolve => { //请求第二个数组
    //     //  console.log('resolve', resolve)
    //     if (resolve.data.code == 1) {
    //       let arr2 = [] //这个arr2要负载到第一个数组的每一项children中
    //       resolve?.data?.data.forEach(it => { //遍历第二个数组
    //         category({ supplier_id: it.id }).then(res => { //请求第三个数组
    //           if (res.data.code == 1) {
    //             let arr3 = [] //这个arr3要负载到第二个数组的每一项children中
    //             res?.data?.data.forEach(t => { //遍历第三个数组
    //               obj3 = { label: t.category_name, value: t.id } //将值赋值到第三数组的项中
    //               arr3.push(obj3) //再添加到第三数组中
    //             })
    //           obj2 = { label: it.supplier_company, value: it.id, children: arr3 } //将值赋值到第二数组的项中，同时赋值第三数组
    //           arr2.push(obj2) //再添加到第二数组中
    //           }
    //         })
    //       })
    //     obj1 = { label: item.name, value: item.id, children: arr2 } //将值赋值到第一数组的项中，同时赋值第二数组
    //     this.options.push(obj1) //再添加到需要展示的数组中
    //     }
    //   })
      })
      this.options = data.data //需要展示的数组
    
		}
      console.log('arr1', this.options)
    },
    handleItemChange (val) {
      this.ids = val
      // 当用户选择一个新的节点时，动态加载下一级数据
      if (val && val.length === 1) {
        this.loadLevel2(val[0]);
      } else if (val && val.length === 2) {
        this.loadLevel3(val[1]);
      }
    },
    loadLevel2(parentId) {
      // 假设 loadLevel2Data 是一个异步函数，用来加载二级数据
      jzCategory({producer_id:parentId.split('x')[0]}).then(data => {
        const parentOption = this.options.find(o => o.idx === parentId);
        data.data.data.forEach(item => {
          item.name = item.supplier_company
          item.idx = item.id
          item.children = []
        })
        parentOption.children = data.data.data;
      });
    },
    loadLevel3(parentId) {
      // 假设 loadLevel3Data 是一个异步函数，用来加载三级数据
      category({ supplier_id: parentId }).then(data => {
        data.data.data.forEach(item => {
          item.name = item.category_name
          item.idx = item.id
          })
        const parentOption = this.options.find(o => o.children && o.children.some(c => c.idx === parentId));
        if (parentOption) {
          const childOption = parentOption.children.find(c => c.idx === parentId);
          childOption.children = data.data.data;
        }
      });
    },
    logOut() {
      window.localStorage.removeItem("token");
    },
    goindex() {
      this.$router.push({
        path: "/",
      });
    },
    // 去登录/个人中心
    toCenter() {
      if (getToken()) {
        this.$router.push({
          path: "/my",
          query: { mynavid: 1 },
        });
      } else {
        window.localStorage.removeItem("token");
      }
    },
    handleChange (value) {
      console.log(value,'jjkkk')
      this.$router.push({
        path: "/shoppingMall",
        query: {
          producer_category_id: this.ids[0].split('x')[0],
          jz_category_id: this.ids[1],
          categoryid: value,
        }
      });
      console.log(value);
      // this.$router.go(0)
    },
    handeleExChange(arr) {
      console.log(arr,'bbbb')
    },
    handleShow(index) {
      this.$emit("onHandleShow", index);
      if (index == 0) {
        this.$router.push({
          path: "/",
        });
      } else if (index == 1) {
        this.$router.push({
          path: "/shoppingMall",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/newsCenter",
        });
      }
    },
    //跳转个人中心
    goUser() {
      if (window.localStorage.getItem("token")) {
        this.$router.push({
          path: "/UserInfo"
        })
      }
      else {
        this.$message.error('请登录！')
      }
    },
    gohoutai() {//跳转到后台
      window.open('http://putaojiuerp.xxzcloud.com/index.html#/admin', "_blank")
    },
    goShopT() {//跳转到购物车
      this.$router.push({
        path: "/shoppingTrolley"
      })
    },
    async fetchIndexBanner() { //logo和客服
      let { data } = await webMsg({
        type: 4 //底部信息type
      });
      console.log(data)
      this.footerinfo = data.data;  //底部信息
    },
    findShop() {//搜索商品
      this.$router.push({
        path: '/shoppingMall',
        query: {
          keywords: this.inputShop
        }
      })
      this.$router.go(0)
    },
    async goPage() { //去用户协议
      // this.$router.push({
      //   path: '/footerPage',
      //   query: {
      //     type: 1, //用户注册协议
      //   }
      // })
      let { data } = await webMsg({
                type: 1
      });
            this.msgdialogVisible = true
            console.log('隐私', data)
            this.content = data.data.user_content
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  border: 1px solid #D7000F;
  border-radius: 0 0px 0px 0;
}

::v-deep [data-v-18ab4752] .el-input-group__append {
  border-radius: 0 0px 0px 0;
}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 0;
}

.login {
  padding: 80px 60px;

  .typeact {
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 1.125rem;
    color: #333333;
    position: relative;
  }

  .typeact::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 72px;
    height: 2px;
    border-radius: 1px;
    background: #d7000f;
  }

  .sbmitbtn {
    cursor: pointer;
    width: 100%;
    height: 3.125rem;
    background: #d7000f;
    border-radius: 0.3125rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    color: #ffffff;
    line-height: 3.125rem;
    text-align: center;
  }

  .seleicon {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #e9e9e9;
    cursor: pointer;
  }

  .seleiconact {
    width: 14px;
    height: 14px;
    background-color: #d7000f;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    text-align: center;
    line-height: 14px;
    font-size: 10px;
    color: #ffffff;
    cursor: pointer;
  }

  ::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color: #ffffff;
    background-image: none;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    color: #999999;
    display: inline-block;
    font-size: inherit;
    height: 50px;
    line-height: 50px;
    outline: none;
    padding: 0 65px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }

  ::v-deep .el-input__prefix {
    border-radius: 5px 0 0 5px;
    position: absolute;
    height: 100%;
    left: 0 !important;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    width: 50px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .el-input__suffix {
    position: absolute;
    height: 100%;
    right: -15px;
    top: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    pointer-events: none;
  }
}

li:hover {
  color: #d7000f;
}

.logo {
  width: 18.4375rem;
  height: 4.4375rem;
}

::v-deep .el-input-group__append,
.el-input-group__prepend {
  background-color: #d7000f;
  color: #ffffff;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 0 solid #dcdfe6;
  border-radius: 0 4px 4px 0;
  padding: 0 20px;
  width: 1px;
  font-size: 0.875rem;
  white-space: nowrap;
}

.cascader {
  ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 0 !important;
  }

  ::v-deep .el-input__inner {
    // width: 15.25rem;
    // height: 2.75rem;
    padding: 0.875rem 4.625rem;
    -webkit-appearance: none;
    background: #d7000f;
    background-image: none;
    border-radius: 0px;
    border: 0 solid #dcdfe6;
    box-sizing: border-box;
    color: #ffffff !important;
    font-size: 1rem;
    line-height: 2.75rem;
    padding: 0;
    text-align: center;

  }

  ::v-deep .el-input__inner::placeholder {
    color: #ffffff;
  }

  ::v-deep .el-input__suffix {
    display: none !important;
  }

}

::v-deep .el-cascader-node.in-active-path,
.el-cascader-node.is-selectable.in-checked-path,
.el-cascader-node.is-active {
  color: #D7000F !important;
}

// .pc-sel-area-cascader{
//   color: #D7000F !important;
// }
//   ::v-deep .el-cascader-node.in-active-path, .el-cascader-node.is-selectable.in-checked-path, .el-cascader-node.is-active{
//   color: #D7000F !important;
// }
// .el-cascader-node.in-active-path,
// .el-cascader-node.is-active,
// .el-cascader-node.is-selectable.in-checked-path {
//   color: #D7000F;
// }
</style>
