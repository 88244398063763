import server from "@/utils/request";

//酒庄api
//网站信息/api/index/web
export function webMsg(data) {
    return server({
        method: "GET",
        url: "/api/index/web",
        params: data
    })
}
//网站底部详情 {{bd}}/api/index/helpInfo?id=2
export function helpInfo(data) {
    return server({
        method: "GET",
        url: "/api/index/helpInfo",
        params: data
    })
}
//获取登录验证码
export function getCaptcha(data) {
    return server({
        method: "POST",
        url: "/api/user/getCaptcha",
        data
    })
}
//用户登录
export function login(data) {
    return server({
        method: "POST",
        url: "/api/user/login",
        data
    })
}
//用户注册
export function register(data) {
    return server({
        method: "POST",
        url: "/api/user/register",
        data
    })
}
//保持登录信息/api/center/info
export function userinfo(data) {
    return server({
        method: "GET",
        url: "/api/center/info",
        params: data
    })
}
//首页列表/api/index/index
export function indexList(data) {
    return server({
        method: "GET",
        url: "/api/index/index",
        params: data
    })
}
//酒品分类
export function category(data) {
    return server({
        method: "GET",
        url: "/api/index/category",
        params: data
    })
}
//产地分类
export function producerCategory(data) {
    return server({
        method: "GET",
        url: "/api/index/producerCategory",
        params: data
    })
}
//酒庄分类
export function jzCategory(data) {
    return server({
        method: "GET",
        url: "/api/index/jzCategory",
        params: data
    })
}
//商品页列表
export function goodsList(data) {
    return server({
        method: "GET",
        url: "/api/index/goodsList",
        params: data
    })
}
//商品详细/api/index/goodsInfo
export function goodsInfo(data) {
    return server({
        method: "GET",
        url: "/api/index/goodsInfo",
        params: data
    })
}
//新闻分类api/index/newsCategory
export function newsCategory(data) {
    return server({
        method: "GET",
        url: "/api/index/newsCategory",
        params: data
    })
}
//新闻列表api/index/newsCategory
export function news(data) {
    return server({
        method: "GET",
        url: "/api/index/news",
        params: data
    })
}
//新闻详情api/index/newsCategory
export function newsInfo(data) {
    return server({
        method: "GET",
        url: "/api/index/newsInfo",
        params: data
    })
}
//购物车列表
export function carIndex(data) {
    return server({
        method: "GET",
        url: "/api/car/index",
        params: data
    })
}
//集采是否为统一商品api/car/checkSameGoods
export function checkSameGoods(data) {
    return server({
        method: "POST",
        url: "/api/car/checkSameGoods",
        data
    })
}
//加入购物车/api/car/add
export function carAdd(data) {
    return server({
        method: "POST",
        url: "/api/car/add",
        data
    })
}
//修改购物车商品数据
export function carEdit(data) {
    return server({
        method: "POST",
        url: "/api/car/edit",
        data
    })
}
//删除购物车商品/api/car/del
export function carDel(data) {
    return server({
        method: "POST",
        url: "/api/car/del",
        data
    })
}
//获取地址列表/api/address/index
export function getAddress(data) {
    return server({
        method: "GET",
        url: "/api/address/index",
        params: data
    })
}
//新增地址/api/address/add
export function addAddress(data) {
    return server({
        method: "POST",
        url: "/api/address/add",
        data
    })
}
//修改地址/api/address/edit
export function editAddress(data) {
    return server({
        method: "POST",
        url: "/api/address/edit",
        data
    })
}
//删除地址/api/address/del
export function delAddress(data) {
    return server({
        method: "POST",
        url: "/api/address/del",
        data
    })
}
//获取地址标签/api/address/tags
export function addressTags(data) {
    return server({
        method: "GET",
        url: "/api/address/tags",
        params: data
    })
}
//获取地址详情/api/address/info
export function addressInfo(data) {
    return server({
        method: "POST",
        url: "/api/address/info",
        data
    })
}
//确认订单（正常下单和集采）/api/order/sure
export function sure(data) {
    return server({
        method: "POST",
        url: "/api/order/sure",
        data
    })
}
//提交订单/api/order/submit
export function submit(data) {
    return server({
        method: "POST",
        url: "/api/order/submit",
        data
    })
}
//生成集采链接/api/order/gatherUrl
export function gatherUrl(data) {
    return server({
        method: "POST",
        url: "/api/order/gatherUrl",
        data
    })
}
//订单详情{{bd}}/api/order/ordinaryInfo?id=1
export function ordinaryInfo(data) {
    return server({
        method: "GET",
        url: "/api/order/ordinaryInfo",
        params: data
    })
}
//支付订单/api/order/pay
export function pay(data) {
    return server({
        method: "POST",
        url: "/api/order/pay",
        data
    })
}
//订单列表/api/order/ordinary
export function ordinary(data) {
    return server({
        method: "GET",
        url: "/api/order/ordinary",
        params: data
    })
}
//取消订单/api/order/cancel
export function cancel(data) {
    return server({
        method: "POST",
        url: "/api/order/cancel",
        data
    })
}
//删除订单 /api/order/delete
export function deleteOrder(data) {
    return server({
        method: "POST",
        url: "/api/order/delete",
        data
    })
}
//申请退货、退款/api/order/applyRefund
export function applyRefund(data) {
    return server({
        method: "POST",
        url: "/api/order/applyRefund",
        data
    })
}
//退款原因 /api/order/refundReason
export function refundReason(data) {
    return server({
        method: "GET",
        url: "/api/order/refundReason",
        params: data
    })
}
//确认收货/api/order/collect
export function collect(data) {
    return server({
        method: "POST",
        url: "/api/order/collect",
        data
    })
}
//填写快递信息/api/order/writeLogistics
export function writeLogistics(data) {
    return server({
        method: "POST",
        url: "/api/order/writeLogistics",
        data
    })
}
//已填写快递信息/api/order/logisticsInfo
export function logisticsInfo(data) {
    return server({
        method: "POST",
        url: "/api/order/logisticsInfo",
        data
    })
}
//平台收货信息/api/order/platformAddress
export function platformAddress(data) {
    return server({
        method: "GET",
        url: "/api/order/platformAddress",
        params: data
    })
}
//站内消息列表{{bd}}/api/center/messageList?page=&per_page=
export function messageList(data) {
    return server({
        method: "GET",
        url: "/api/center/messageList",
        params: data
    })
}
//站内消息详情{{bd}}/api/center/messageInfo?id=1
export function messageInfo(data) {
    return server({
        method: "GET",
        url: "/api/center/messageInfo",
        params: data
    })
}
//在线留言
export function stay(data) {
    return server({
        method: "POST",
        url: "/api/index/stay",
        data
    })
}
//申请发票
export function applyInvoice(data) {
    return server({
        method: "POST",
        url: "/api/order/applyInvoice",
        data
    })
}
//开票信息
export function invoiceData(data) {
    return server({
        method: "POST",
        url: "/api/order/invoiceData",
        data
    })
}
//查看物流
export function getLogistics(data) {
    return server({
        method: "POST",
        url: "/api/ajax/getLogistics",
        data
    })
}