<template>
  <div>
    <div class="pofix">
      <div class="iconbox row a_center j_center column mb10 icon1" @click="dialogVisible=true">
        <div class="">
          <img src="../assets/img/kefu.png" alt="" class="wh21" />
        </div>
        在线留言
      </div>
      <el-popover placement="right" trigger="hover">
        <div class="iconbox row a_center j_center column mb10 icon2" slot="reference">
          <div class="">
            <img src="../assets/img/dianhua.png" alt="" class="wh21" />
          </div>
          客服电话
        </div>
        <div>
          <div class="info_color ft14 text_center mb10">客服电话</div>
          <div class="c_D7000F ft16 text_center">{{ phone }}</div>
        </div>
      </el-popover>
      <el-popover placement="right" trigger="hover">
        <div class="iconbox row a_center j_center column mb10 icon3" slot="reference">
          <div class="">
            <img src="../assets/img/weixin.png" alt="" class="wh21" />
          </div>
          客服微信
        </div>
        <div class="p20 row a_center">
          <div class="row a_center j_center column ft14 input_color mr20" v-for="(item, index) in wxList" :key="index">
            <div class="mb10">
              <img :src="item.image" alt="" class="wh80" />
            </div>
            <div>{{ item.name }}</div>
          </div>
        </div>
      </el-popover>
      <div class="iconbox row a_center j_center column mb10 icon4" @click="scrollToTop">
        <div class="">
          <img src="../assets/img/dingbu.png" alt="" class="wh21" />
        </div>
        返回顶部
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="40rem" custom-class="br4" center>
      <div class=" ft18" style="min-height: 5rem;">
        <div class="mb30 row a_center j_center">
          <div class="shrink" style="width:90px;text-align:right">联系方式：</div>
          <el-input placeholder="请输入联系方式" v-model="contact" style="width:400px">
          </el-input>
        </div>
        <div class="mb30 row j_center">
          <div class="shrink" style="width:90px;text-align:right">留言：</div>
          <el-input placeholder="请输入留言" v-model="content" style="width:400px" type="textarea" rows="5">
          </el-input>
        </div>
      </div>
      <div class="row j_se">
        <el-button style="width: 100px;background-color: #DDDDDD ;color: #666666 ;"
          @click="dialogVisible = false">取消</el-button>
        <el-button type="danger" style="width: 100px;background-color: #E1251B ;" @click="sendstay()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { webMsg, stay } from "@/api/app";

export default {
  data() {
    return {
      phone: '',
      wxList: [],
      dialogVisible:false,
      contact: '',
      content: ''
    };
  },
  mounted() {
    this.getPhone()
    this.getVX()
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动效果
      });
    },
    //获取电话号码信息
    async getPhone() {
      let { data } = await webMsg({
        type: 4 //底部信息type
      });
      this.phone = data.data?.platform_phone;  //底部信息
    },
    //获取客服vx列表
    async getVX() {
      let { data } = await webMsg({
        type: 3 //底部信息type
      });
      this.wxList = data.data?.wechat_service;  //底部信息
    },
    //在线留言
    async sendstay() {
      let { data } = await stay({
        contact: this.contact,
        content: this.content
      });
      if (data.code == 1) {
        this.$message.success(data.msg)
      }
      else {
        this.$message.error(data.msg)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pofix {
  position: fixed;
  right: 10px;
  bottom: 300px;
  z-index: 1001;
}

.iconbox {
  width: 60px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  text-align: center;
  cursor: pointer;
  // .icon {
  //   color: #333333;
  // }
}

.iconbox:hover {
  background: #d7000f;
  color: #ffffff;
  // .icon {
  //   color: #ffffff;
  // }
}

.icon1:hover img {
  content: url(../assets/img/kefuw.png);
}

.icon2:hover img {
  content: url(../assets/img/dianhuaw.png);
}

.icon3:hover img {
  content: url(../assets/img/weixinw.png);
}

.icon4:hover img {
  content: url(../assets/img/dingbuw.png);
}
</style>
