import axios from "axios";
import {
    getToken
} from "./auth";
import router from "../router/index"
import {
    Message
} from 'element-ui';
// 创建axios
let token = window.localStorage.getItem("token") //这里就拿到了token
const server = axios.create({
    // baseURL: "/", 
    baseURL:"https://admin.ptjerp.com", //测试域名
    // baseURL: "http://zaishaonian.xxzcloud.com", //配置请求的公共前缀
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'ba-user-token': token,
        "server": 1,
    }
})

/* 请求拦截器 + 相应拦截器  */

server.interceptors.request.use((config) => {

    // config.headers.Authorization = getToken()
    return config

})


/* 
  统一处理相应的
*/

server.interceptors.response.use((res) => {

    /* 成功回来之后，把数据给到之前执行的  */
    if (res.data.code == 303||res.data.code == 302) {
        Message.error('未登录，请登录！！！')
        window.localStorage.removeItem("token");
    }
    // if(!res.code){

    //     if(res.code == -10){

    //         window.localStorage.removeItem("token");
    //         router.push("/index")

    //     }

    // }

    return res

})



export default server
